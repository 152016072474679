import React from 'react';

import bike from './assets/types/bike.svg';
import bikeActive from './assets/types/bike-active.svg';

import car from './assets/types/car.svg';
import carActive from './assets/types/car-active.svg';

import drive from './assets/types/drive.svg';
import driveActive from './assets/types/drive-active.svg';

import scooter from './assets/types/scooter.svg';
import scooterActive from './assets/types/scooter-active.svg';

import taxi from './assets/types/taxi.svg';
import taxiActive from './assets/types/taxi-active.svg';

import walk from './assets/types/walk.svg';
import walkActive from './assets/types/walk-active.svg';

import helicopter from './assets/types/helicopter.svg';
import helicopterActive from './assets/types/helicopter-active.svg';

export const API_URL = '';

export const types = {
    CAR: 'car',
    WALK: 'walk',
    TAXI: 'taxi',
    BIKE: 'bike',
    SCOOTER: 'scooter',
    DRIVE: 'drive',
};

export const typeNames = {
    [types.CAR]: 'Авто',
    [types.WALK]: 'Пешком',
    [types.TAXI]: 'Такси',
    [types.BIKE]: 'Велосипед',
    [types.SCOOTER]: 'Самокат',
    [types.DRIVE]: 'Драйв',
};

export const typeIcons = {
    [types.CAR]: {
        active: carActive,
        default: car,
    },
    [types.WALK]: {
        active: walkActive,
        default: walk,
    },
    [types.TAXI]: {
        active: taxiActive,
        default: taxi,
    },
    [types.BIKE]: {
        active: bikeActive,
        default: bike,
    },
    [types.SCOOTER]: {
        active: scooterActive,
        default: scooter,
    },
    [types.DRIVE]: {
        active: driveActive,
        default: drive,
    },
    helicopter: {
        active: helicopterActive,
        default: helicopter,
    },
    aero: {
        active: helicopterActive,
        default: helicopter,
    },
};

export const modes = {
    OPTIMAL: 'optimal',
    FASTEST: 'fastest',
    CUSTOM: 'custom',
};

export const TypeMap = {
    [types.CAR]: 'auto',
    [types.WALK]: 'pedestrian',
    [types.TAXI]: 'auto',
    [types.BIKE]: 'pedestrian',
    [types.SCOOTER]: 'pedestrian',
    [types.DRIVE]: 'auto',
};

export const modeNames = {
    [modes.OPTIMAL]: 'Оптимальный',
    [modes.FASTEST]: 'Быстрый',
    [modes.CUSTOM]: 'Свой',
};

export const modesFlat = [modes.OPTIMAL, modes.FASTEST, modes.CUSTOM];
export const typesFlat = [
    types.CAR,
    types.WALK,
    types.TAXI,
    types.BIKE,
    types.SCOOTER,
    types.DRIVE,
];

export const Tariffs = {
    ECONOMY: 'Рабочий',
    COMFORT: 'Сонный',
    COMFORT_PLUS: 'Отпускной',
    VAN: 'Разговорчивый',
    KIDS: 'Утешительный',
    BUSINESS: 'Глянцевый',
    DRIVE_1: 'Повседневный',
    DRIVE_2: 'Походный',
    DRIVE_3: 'Роскошный',
};

export const RequirementsList = {
    NOTEBOOK_DESC: 1,
    NEON: 2,
    MASSAGE_CHAIR: 3,
    MASK: 4,
    PATCHES: 5,
    PLUSHIE: 6,
    BED_DUBAI: 7,
    BED_YAKUT: 8,
    PILLOW: 9,
    SOFT_DRIVE: 10,
    PLAID: 11,
    SOUND_PROOF: 12,
    ICECREAM_PL: 13,
    ICECREAM_CH: 14,
    ICECREAM_BR: 15,
    SPARKLES: 16,
    ICECREAM: 17,
    BED: 18,
    FOR_YOURSELF: 19,
    // Drive:
    PHONE_CHARGER: 20,
    COLOR_MUSIC: 21,
    MOSCITOS_SPRAY: 22,
    SLEEPBAG: 23,
    SHAMPAIN: 24,
    VINE: 25,
    GLASSES: 26,
    FUN: 27,
};

export const TariffIds = {
    [Tariffs.ECONOMY]: 'working',
    [Tariffs.COMFORT]: 'sleepy',
    [Tariffs.COMFORT_PLUS]: 'vacation',
    [Tariffs.VAN]: 'talkative',
    [Tariffs.KIDS]: 'comforting',
    [Tariffs.BUSINESS]: 'glossy',
    [Tariffs.DRIVE_1]: 'everyday',
    [Tariffs.DRIVE_2]: 'camping',
    [Tariffs.DRIVE_3]: 'luxury',
};
