import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import Input from '../Input';
import Button from '../Button';
import CloseButton from '../CloseButton';
import { Formik } from 'formik';
import './styles.css';
import { API_URL } from '../../constants';

const NumberPicker = (props) => {
    const { isOpen = false, phone, changePhone, done } = props;
    const [ph, setPh] = useState(phone);
    const [section, setSection] = useState(0);
    const [code, setCode] = useState('');
    const [vcode, setVCode] = useState(null);
    const [need2sendCode, setNeed2sendCode] = useState(false);
    const [timer, setTimer] = useState(0);
    const [need2StartTimer, startTimer] = useState(false);

    const close = () => {
        done();
        setSection(0);
    };

    const sendCode = useCallback((sec) => {
        setSection(sec);
        setNeed2sendCode(true);
        startTimer(true);
    }, []);

    const approveCode = useCallback(
        (values) => {
            if (`${values.code}` === `${vcode}`) {
                changePhone(ph);
                done();
                setSection(0);
                setCode(values.code);
            }
        },
        [vcode, ph]
    );

    useEffect(() => {
        if (need2StartTimer) {
            setTimer(59);
            startTimer(false);
        }

        if (timer !== 0) {
            setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        }
    }, [need2StartTimer, timer]);

    useEffect(() => {
        if (!need2sendCode || !ph) {
            return;
        }

        setNeed2sendCode(false);
        return fetch(`${API_URL}/api/v1/number?number=${ph}`)
            .then((res) => res.json())
            .then((res) => setVCode(res.code));
    }, [need2sendCode, ph]);

    return (
        <div className={cn('number-picker', { open: isOpen })}>
            <div className="overlay" />
            <div className="modal">
                <div className={cn('section', { active: section === 0 })}>
                    <CloseButton onClick={close} customClass="section-close" />
                    <div className="head">Введите номер телефона</div>
                    <Formik
                        initialValues={{ phone }}
                        onSubmit={(values) => {
                            setPh(values.phone);
                            sendCode(1);
                        }}
                        validate={(values) => {
                            const errors = {};

                            if (
                                !values.phone ||
                                !/^(\+7|8)\s?[0-9]{3}\s?[0-9]{3}\s?[0-9]{2}\s?[0-9]{2}$/.test(
                                    values.phone
                                )
                            ) {
                                errors.phone = 'Введите корректный номер';
                            }
                            return errors;
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="np-input">
                                    <Input
                                        withoutButton
                                        name="phone"
                                        onChangePure={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                        id="phone"
                                        label="Телефон"
                                        placeholder="+7 ххх ххх хх хх"
                                        error={touched.phone && errors.phone}
                                    />
                                </div>
                                <div className="buttons">
                                    <Button type="submit" isFull>
                                        Далее
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
                <div className={cn('section', { active: section === 1 })}>
                    <CloseButton onClick={close} customClass="section-close" />
                    <div className="head">Ввидите код из СМС</div>
                    <Formik
                        initialValues={{ code }}
                        onSubmit={approveCode}
                        validate={(values) => {
                            const errors = {};

                            if (
                                !values.code ||
                                !/^[0-9]{4}$/.test(values.code)
                            ) {
                                errors.code = 'Введите корректный код';
                            }

                            return errors;
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="np-input">
                                    <Input
                                        id="code"
                                        onChangePure={handleChange}
                                        value={values.code}
                                        onBlur={handleBlur}
                                        label="Введите код"
                                        placeholder="хххх"
                                        error={touched.code && errors.code}
                                    />
                                </div>
                                <div className="buttons">
                                    <Button type="submit" isFull>
                                        Подтвердить
                                    </Button>
                                    <Button
                                        isFull
                                        isDisabled={timer !== 0}
                                        onClick={() => sendCode(1)}
                                    >
                                        {timer !== 0
                                            ? 'Отправить код еще раз 00:' +
                                              (timer >= 10
                                                  ? timer
                                                  : `0${timer}`)
                                            : 'Отправить код еще раз'}
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export const NumberPickerButton = (props) => {
    const { phone, onClick } = props;

    return (
        <div onClick={onClick} className={cn('np-button', { filled: !!phone })}>
            <div className="np-text">{phone || 'Телефон'}</div>
        </div>
    );
};

export default NumberPicker;
