import React, { useCallback, useEffect, useState } from 'react';
import './styles.css';
import {
    getAddressIndex,
    isReferenceAddress,
    refAddresses,
} from '../../utils/directions';
import { types, TypeMap } from '../../constants';
import marker from '../../assets/sharing/small.svg';

const ymaps = window.ymaps || {};

const mapConfig = {
    center: [55.724452, 37.572764],
    zoom: 14,
    controls: ['zoomControl'],
};

const Map = (props) => {
    const { destinations, cars, type } = props;

    const [ymap, setYmap] = useState(null);
    const [multiRoute, setMultiRoute] = useState(null);
    const [icons, setIcons] = useState([]);

    useEffect(() => {
        ymaps.ready(() => {
            const mp = new ymaps.Map('map', mapConfig);

            setYmap(mp);
            setTimeout(() => {
                const mr = new ymaps.multiRouter.MultiRoute(
                    {
                        referencePoints: [],
                        params: { avoidTrafficJams: true },
                    },
                    {
                        preset: 'islands#multiRouterFilled',
                        balloonLayout: null,
                    }
                );

                setMultiRoute(mr);
            }, 800);
        });
    }, []);

    useEffect(() => {
        if (ymap !== null && multiRoute !== null) {
            ymap.geoObjects.add(multiRoute);
        }
    }, [ymap, multiRoute]);

    useEffect(() => {
        if (ymap === null && !cars.length) {
            return;
        }

        if (icons.length === cars.length) {
            return;
        }

        icons.forEach((i) => ymap.geoObjects.remove(i));

        const points = cars.map(({ location, tariff }) => {
            const p = new ymaps.Placemark(
                [location.latitude, location.longitude],
                {
                    hintContent: tariff,
                },
                {
                    iconLayout: 'default#image',
                    iconImageHref: marker,
                    iconImageSize: [44 / 3, 84 / 3],
                }
            );

            ymap.geoObjects.add(p);

            return p;
        });

        setIcons(points);
    }, [ymap, cars, icons]);

    const updateMap = useCallback(
        (to, from, type = types.WALK) => {
            if (!multiRoute) {
                return;
            }

            const routeFrom = isReferenceAddress(from)
                ? refAddresses[getAddressIndex(from)]
                : '';
            const routeTo = isReferenceAddress(to)
                ? refAddresses[getAddressIndex(to)]
                : '';

            multiRoute.model.setReferencePoints([routeFrom, routeTo]);
            multiRoute.model.setParams({ routingMode: TypeMap[type] });
        },
        [multiRoute]
    );

    useEffect(() => {
        if (ymap !== null && multiRoute !== null && destinations.isValid) {
            updateMap(destinations.to, destinations.from, type);
        }
    }, [
        destinations.to,
        destinations.from,
        destinations.isValid,
        ymap,
        type,
        multiRoute,
        updateMap,
    ]);

    return (
        <div className="map">
            <div id="map" />
        </div>
    );
};

export default Map;
