import React from 'react';
import cn from 'classnames';
import './styles.css';

const Counter = (props) => {
    const { value = 0, onChange, max } = props;

    const plus = () => {
        if ((max && value + 1 <= max) || !max) {
            onChange(value + 1);
        }
    };

    const minus = () => {
        if (value - 1 >= 0) {
            onChange(value - 1);
        }
    };

    return (
        <div className="counter">
            <div
                className={cn('counter-minus', { disabled: value - 1 < 0 })}
                onClick={minus}
            >
                &ndash;
            </div>
            <div className="counter-value">{value}</div>
            <div
                className={cn('counter-plus', {
                    disabled: max && value + 1 > max,
                })}
                onClick={plus}
            >
                +
            </div>
        </div>
    );
};

export default Counter;
