import Fuse from 'fuse.js';

export const refAddresses = [
    'Усачева, 3',
    'Комсомольский проспект, 18',
    'Зубовский бульвар, 37',
    'М. Пироговская, 25',
    'Хамовнический Вал, 34',
    'Фрунзенская набережная, 46',
    '3-я Фрунзенская улица, 12',
];

const distancesAuto = [
    [0.0, 1.4, 1.5, 0.89, 2.6, 2.6, 2.6],
    [1.4, 0.0, 2.9, 2.3, 2.3, 2.3, 2.3],
    [1.4, 1.5, 0.0, 1.9, 3.8, 3.0, 3.3],
    [1.5, 3.0, 2.4, 0.0, 1.2, 3.4, 2.3],
    [1.5, 3.7, 3.7, 1.2, 0.0, 1.7, 1.7],
    [3.2, 3.9, 4.7, 2.7, 1.7, 0.0, 2.2],
    [1.4, 2.4, 3.5, 2.3, 1.4, 1.3, 0.0],
];

const distancesWalk = [
    [0.0, 0.96, 1.4, 0.91, 1.4, 1.7, 1.1],
    [1.0, 0.0, 1.3, 1.9, 2.0, 1.7, 1.2],
    [1.4, 1.3, 0.0, 1.9, 2.7, 2.7, 2.3],
    [0.91, 1.9, 1.9, 0.0, 0.75, 1.5, 1.2],
    [1.4, 2.0, 2.7, 0.75, 0.0, 1.4, 1.2],
    [1.7, 1.7, 2.7, 1.5, 1.4, 0.0, 0.57],
    [1.1, 1.2, 2.3, 1.2, 1.2, 0.57, 0.0],
];

const options = {
    includeScore: true,
};

const fuse = new Fuse(refAddresses, options);

export const getAddressIndex = (address) => {
    const result = fuse.search(address);

    if (`${address}`.length < 3) {
        return -1;
    }

    if (result && result.length) {
        const [first] = result;

        if (first && 1 - first.score > 0.5) {
            return refAddresses.indexOf(first.item);
        }
    }

    return -1;
};
export const isReferenceAddress = (address) => {
    const result = fuse.search(address);

    if (`${address}`.length < 3) {
        return false;
    }

    if (result && result.length) {
        const [first] = result;

        if (first && 1 - first.score > 0.5) {
            return true;
        }
    }

    return false;
};

function getDistance(from, to, map) {
    return isReferenceAddress(from) && isReferenceAddress(to)
        ? map[getAddressIndex(from)][getAddressIndex(to)]
        : -1;
}

export const getDistances = (from, to) => {
    return {
        distanceAuto: getDistance(from, to, distancesWalk),
        distanceWalk: getDistance(from, to, distancesAuto),
    };
};
