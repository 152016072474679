import React from 'react';
import Logo from '../Logo';
import DestinationPicker from '../DestinationPicker';

import './styles.css';
import TypePicker from '../TypePicker';
import TariffPicker from '../TariffPicker';
import SmartButton from '../SmartButton';

const Workflow = (props) => {
    return (
        <div className="workflow">
            <Logo />
            <DestinationPicker {...props} />
            <div className="workflow-subcontainer">
                <TypePicker {...props} />
                <TariffPicker {...props} />
            </div>
            <SmartButton {...props} />
        </div>
    );
};

export default Workflow;
