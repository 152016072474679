import React from 'react';
import cn from 'classnames';

import './style.css';
import CloseButton from '../CloseButton';

const Input = (props) => {
    const {
        value,
        type = 'text',
        id,
        name,
        label,
        error,
        placeholder,
        onChange,
        onChangePure,
        onBlur,
        withoutButton = false,
    } = props;

    const _onChange = (event) => {
        const value = event.target.value;

        if (onChange) {
            onChange(value);
        }
        if (onChangePure) {
            onChangePure(event);
        }
    };

    return (
        <>
            <div className={cn('input-container', { error: !!error })}>
                <input
                    id={id}
                    className="input"
                    value={value}
                    onBlur={onBlur}
                    onChange={_onChange}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                />
                <label htmlFor={id} className="label">
                    {label}
                </label>
                {!withoutButton && (
                    <CloseButton
                        customClass="input-close-button"
                        onClick={() => onChange('')}
                    />
                )}
            </div>
            {!!error && <div className="error">{error}</div>}
        </>
    );
};

export default Input;
