import { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import './App.css';
import Map from './components/Map';
import Workflow from './components/Workflow';
import {
    API_URL,
    modes,
    TariffIds,
    Tariffs,
    typeNames,
    types,
} from './constants';
import { getDistances } from './utils/directions';
import NumberPicker from './components/NumberPicker';
import PaymentPicker from './components/PaymentPicker';
import Order from './components/Order';
import RightsPicker from './components/RightsPicker';

const COOKIE_NAME = 'n33d2sh0wcust0m';
const LOCAL_STORAGE_NAME = '__react__hooks__storage__main';
const FAKE_STORAGE_NAME = '__react__hooks__storage__replica';

const buildLSObject = (tariff, features) => ({
    version: '1.0',
    sprint: '4',
    _author: 'www.imgur.com/a/G6rU8',
    __test__zeroTimers: false,
    __test__tariffs: Tariffs,
    __test__components: [
        {
            __name: '_AppComponent34JdD',
            __state: true,
            __react_id: '2d189ehfiahoF12AJiwe21',
            components: [],
        },
        {
            __name: '_TypePicker9KDA2',
            __state: false,
            __react_id: '}ZPHd-E$<_1g)V1',
            components: [],
        },
        {
            __name: '_OrderPopup1P4Sa',
            __state: true,
            __react_id: 'GmU2r@iBc,;1:0n',
            components: [
                {
                    __name: '__Subscribe2PewDiePie',
                },
            ],
        },
    ],
    __auth: {
        yandex_maps: {
            oauth: {
                access_token: '7WxirqKBFRydCV0AuJ7KtXS2WkdmupJI',
                expires_in: 36000,
                refresh_token: 'FtFqCzz7BKh3fHEl9VRXyHIJzRISAau5',
                token_type: 'bearer',
                account_id: 914515,
            },
        },
    },
    data: {
        tariff,
        tariffPerks: {
            activeTariff: tariff,
            features,
        },
    },
});

const getDataFromLS = (obj) => {
    const tariff = obj?.data?.tariff;
    const features = obj?.data?.tariffPerks?.features;

    return { tariff, features };
};

export const states = {
    ADDRESS: 0,
    TYPE_PICK: 1,
    TARIFF_PICK: 2,
};

export const payments = {
    CASH: 'cash',
    CARD: 'card',
};

function App() {
    const [destinations, updateDestinations] = useState({
        to: '', // 'Фрунзенская набережная, 46',
        from: '', //'Зубовский бульвар, 37',
        isValid: false,
    });
    const [type, setType] = useState(types.CAR);
    const [mode, setMode] = useState(modes.FASTEST);
    const [result, setResult] = useState(null);
    const [state, setState] = useState(states.ADDRESS);
    const [phone, setPhone] = useState('');
    const [phonePicker, setPhonePicker] = useState(false);
    const [comment, setComment] = useState('');
    const [activeTariff, setActiveTariff] = useState(Tariffs.ECONOMY);
    const [requirements, setRequirements] = useState({});
    const [orderShown, setOrderShown] = useState(false);
    const [tariffsPrices, setTariffPrices] = useState([]);
    const [needToShowCustom, setNeedToShowCustom] = useState(false);
    const [loadedFromLS, setLoadedFromLS] = useState(false);

    const [rights, setRights] = useState(null);
    const [rightsPicker, setRightsPicker] = useState(false);

    const [payment, setPayment] = useState(payments.CASH);
    const [paymentPicker, setPaymentPicker] = useState(false);
    const [cars, setCars] = useState([]);

    useEffect(() => {
        const lsData = localStorage.getItem(LOCAL_STORAGE_NAME);

        if (type !== types.TAXI || state !== states.TARIFF_PICK) {
            return;
        }

        const timer = setTimeout(() => {
            try {
                const parsedData = JSON.parse(lsData);

                if (parsedData) {
                    const { tariff, features } = getDataFromLS(parsedData);

                    if (Object.keys(TariffIds).includes(tariff)) {
                        setActiveTariff(tariff);
                    }

                    if (Object.keys(features).length) {
                        setRequirements(features);
                    }
                }
            } catch (e) {
                console.error(e);
            }

            setLoadedFromLS(true);
        }, 10);

        return () => clearTimeout(timer);
    }, [type, state]);

    useEffect(() => {
        if (!loadedFromLS) {
            return;
        }

        localStorage.setItem(
            LOCAL_STORAGE_NAME,
            JSON.stringify(buildLSObject(activeTariff, requirements))
        );

        localStorage.setItem(
            FAKE_STORAGE_NAME,
            JSON.stringify(buildLSObject(activeTariff, requirements))
        );
    }, [activeTariff, requirements, loadedFromLS]);

    useEffect(() => {
        const value = Cookie.get(COOKIE_NAME);

        if (`${value}` !== '0') {
            Cookie.set(COOKIE_NAME, 1);
            setNeedToShowCustom(true);
            return;
        }
        setNeedToShowCustom(false);
    }, []);

    useEffect(() => {
        if (destinations.isValid) {
            setState(states.TYPE_PICK);
        } else {
            setState(states.ADDRESS);
        }
    }, [destinations.isValid]);

    useEffect(() => {
        if (type === types.DRIVE) {
            if (
                ![Tariffs.DRIVE_1, Tariffs.DRIVE_2, Tariffs.DRIVE_3].includes(
                    activeTariff
                )
            ) {
                setActiveTariff(Tariffs.DRIVE_1);
            }
        } else if (type === types.TAXI) {
            if (
                [Tariffs.DRIVE_1, Tariffs.DRIVE_2, Tariffs.DRIVE_3].includes(
                    activeTariff
                )
            ) {
                setActiveTariff(Tariffs.ECONOMY);
            }
        }
    }, [activeTariff, type]);

    useEffect(() => {
        const { from, to, isValid } = destinations;

        if (!isValid) {
            return;
        }

        const { distanceWalk, distanceAuto } = getDistances(from, to);
        if (distanceWalk === -1 || distanceAuto === -1) {
            console.error('distance is not defined');
            return;
        }

        return fetch(
            `${API_URL}/api/v1/estimate?byAuto=${distanceAuto}&byLegs=${distanceWalk}&time=${Date.now()}`
        )
            .then((res) => res.json())
            .then((res) => {
                setResult(res);
                if (res && mode !== modes.CUSTOM) {
                    setType(res.modes[mode]);
                }
            });
    }, [
        mode,
        destinations.isValid,
        destinations.from,
        destinations.to,
        destinations,
    ]);

    useEffect(() => {
        if (!result || !result.estimations) {
            return;
        }

        const flatTariffs = Object.keys(Tariffs).map((key) => Tariffs[key]);

        if (type === types.TAXI) {
            const duration = result.estimations[types.TAXI].duration;
            const promises = flatTariffs
                .filter(
                    (v) =>
                        ![
                            Tariffs.DRIVE_1,
                            Tariffs.DRIVE_2,
                            Tariffs.DRIVE_3,
                        ].includes(v)
                )
                .map((tariff) =>
                    fetch(
                        `${API_URL}/api/v1/tariffs/price?tariff=${TariffIds[tariff]}&duration=${duration}`
                    )
                        .then((res) => res.json())
                        .catch(() => Promise.resolve({ price: 5, tariff }))
                );
            return Promise.all(promises).then((ress) => setTariffPrices(ress));
        }

        if (type === types.DRIVE) {
            const duration = result.estimations[types.DRIVE].duration;
            const promises = flatTariffs
                .filter((v) =>
                    [
                        Tariffs.DRIVE_1,
                        Tariffs.DRIVE_2,
                        Tariffs.DRIVE_3,
                    ].includes(v)
                )
                .map((tariff) =>
                    fetch(
                        `${API_URL}/api/v1/tariffs/price?tariff=${TariffIds[tariff]}&duration=${duration}`
                    ).then((res) => res.json())
                );
            return Promise.all(promises).then((ress) => setTariffPrices(ress));
        }
    }, [result, type]);

    const getResultedType = () => {
        if (mode === modes.CUSTOM) {
            return type;
        }
        return result ? result.modes[mode] : null;
    };

    const bakeEstimations = () => {
        if (result === null) {
            return null;
        }

        const resultedType = getResultedType();
        const estimation = result.estimations[resultedType];
        const price =
            estimation.price === 0
                ? 'Бесплатно'
                : `~ ${Math.round(estimation.price)} руб.`;
        const durationText = `В пути ${Math.round(
            estimation.duration * 60
        )} мин.`;
        const name = typeNames[resultedType];
        const priceText = `${name} ${price}`;

        return {
            priceText,
            durationText,
            duration: estimation.duration * 60,
        };
    };

    useEffect(() => {
        if (type !== types.DRIVE || cars.length) {
            return;
        }

        fetch(`${API_URL}/api/v1/carsharing`)
            .then((r) => r.json())
            .then((r) => setCars(r))
            .catch((e) => console.error(e));
    }, [type, cars]);

    return (
        <div className="app">
            {type === types.TAXI ? (
                <NumberPicker
                    phone={phone}
                    changePhone={setPhone}
                    isOpen={phonePicker}
                    done={() => setPhonePicker(false)}
                />
            ) : (
                <RightsPicker
                    rights={rights}
                    changeRights={setRights}
                    isOpen={rightsPicker}
                    done={() => setRightsPicker(false)}
                />
            )}

            <PaymentPicker
                payment={payment}
                changePayment={setPayment}
                isOpen={paymentPicker}
                done={() => setPaymentPicker(false)}
                type={type}
            />
            <Workflow
                destinations={destinations}
                update={updateDestinations}
                type={type}
                setType={setType}
                mode={mode}
                setMode={setMode}
                state={state}
                next={() => setState(state + 1)}
                prev={() => setState(state - 1)}
                bakedResults={bakeEstimations()}
                phone={phone}
                onPhoneClick={() => setPhonePicker(true)}
                rights={rights}
                onRightsClick={() => setRightsPicker(true)}
                payment={payment}
                onPaymentClick={() => setPaymentPicker(true)}
                comment={comment}
                setComment={setComment}
                activeTariff={activeTariff}
                setActiveTariff={setActiveTariff}
                requirements={requirements}
                setRequirements={setRequirements}
                showOrder={() => setOrderShown(true)}
                tariffPrices={tariffsPrices}
                needToShowCustom={needToShowCustom}
            />
            <Map
                destinations={destinations}
                cars={cars.filter((r) => r.tariff === activeTariff)}
                type={type}
            />
            <Order
                shown={orderShown}
                destinations={destinations}
                activeTariff={activeTariff}
                payment={payment}
                tariffPrices={tariffsPrices}
                phone={phone}
                features={requirements}
                comment={comment}
                reset={() => setOrderShown(false)}
            />
        </div>
    );
}

export default App;
