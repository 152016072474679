import React from 'react';
import cn from 'classnames';

import './styles.css';

const Button = (props) => {
    const {
        type = 'button',
        text,
        children,
        onClick,
        isRound = false,
        isFull = false,
        isDisabled = false,
    } = props;

    return (
        <button
            type={type}
            disabled={isDisabled}
            className={cn('button', {
                round: isRound,
                full: isFull,
                disabled: isDisabled,
            })}
            onClick={() => {
                if (!isDisabled && onClick) {
                    onClick();
                }
            }}
        >
            {text || children}
        </button>
    );
};

export default Button;
