import React, { useState } from 'react';
import Input from '../Input';
import { isReferenceAddress } from '../../utils/directions';

import './styles.css';

const INCORRECT_ADDRESS = 'Введите адрес';

const validate = (values) => {
    const errors = {};

    if (!values.to || !isReferenceAddress(values.to)) {
        errors.to = INCORRECT_ADDRESS;
    }

    if (!values.from || !isReferenceAddress(values.from)) {
        errors.from = INCORRECT_ADDRESS;
    }

    return errors;
};

const DestinationPicker = (props) => {
    const { destinations, update } = props;

    const [errors, setErrors] = useState({});
    const [readyToShowError, setReadyToShowError] = useState({ to: false, from: false });

    const onChange = (field) => (value) => {
        const newValue = {
            to: destinations.to,
            from: destinations.from,
            isValid: false,
        };

        newValue[field] = value;
        const errs = validate(newValue);
        const isValid = Object.keys(errs).length === 0;
        newValue.isValid = isValid;

        update(newValue);
        setErrors(errs);
    };

    const onBlur = (field) => () => {
        const newValue = {
            from: readyToShowError.from,
            to: readyToShowError.to,
        };

        newValue[field] = true;
        setReadyToShowError(newValue);
    };

    return (
        <div className="dst-picker">
            <div className="dst-picker-row">
                <div>
                    <div className="dst-picker-marker" style={{ background: '#FC363B' }} />
                </div>
                <div>
                    <Input
                        label="Откуда"
                        placeholder="Хамовнический Вал, 18"
                        id="from"
                        onChange={onChange('from')}
                        onBlur={onBlur('from')}
                        value={destinations.from}
                        error={errors.from}
                    />
                </div>
            </div>
            <div className="dst-picker-row">
                <div>
                    <div className="dst-picker-marker" style={{ background: '#2F80ED' }} />
                </div>
                <div>
                    <Input
                        label="Куда"
                        placeholder="Льва Толстого, 16"
                        onChange={onChange('to')}
                        onBlur={onBlur('to')}
                        id="to"
                        value={destinations.to}
                        error={errors.to}
                    />
                </div>
            </div>
        </div>
    );
};

export default DestinationPicker;
