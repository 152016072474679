import React, { useState } from 'react';
import cn from 'classnames';
import Button from '../Button';
import CloseButton from '../CloseButton';
import { Formik } from 'formik';
import './styles.css';

const RightsPicker = (props) => {
    const { isOpen = false, rights, changeRights, done } = props;
    const [section, setSection] = useState(0);

    const close = () => {
        done();
        setSection(0);
    };

    return (
        <div className={cn('number-picker', { open: isOpen })}>
            <div className="overlay" />
            <div className="modal" style={{ width: '668px' }}>
                <div
                    className={cn('section', { active: section === 0 })}
                    style={{ gridTemplateColumns: '100%', padding: '60px 0' }}
                >
                    <CloseButton onClick={close} customClass="section-close" />
                    <div className="head">Добавление прав</div>
                    <Formik
                        initialValues={{
                            firstName: rights?.firstName || '',
                            lastName: rights?.lastName || '',
                            birthDate: rights?.birthDate || '',
                            number: rights?.number || '',
                        }}
                        onSubmit={(values) => {
                            changeRights(values);
                            setSection(1);
                        }}
                        validate={(values) => {
                            const errors = {};
                            const { firstName, lastName } = values;

                            if (
                                !firstName ||
                                firstName.length > 14 ||
                                firstName.length < 2 ||
                                !/[а-яё\s-]/i.test(firstName)
                            ) {
                                errors.firstName = 'Введите каректное имя';
                            }

                            if (
                                !lastName ||
                                lastName.length > 14 ||
                                lastName.length < 2 ||
                                !/[а-яё]/i.test(lastName)
                            ) {
                                errors.lastName = 'Введите корректную фамилию';
                            }

                            return errors;
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                style={{ marginTop: '20px' }}
                            >
                                <div className="shitty-wrapper">
                                    <div className="card-number">
                                        <div className="card-number-label">
                                            Имя:
                                        </div>
                                        <div className="card-number-input">
                                            <input
                                                type="text"
                                                value={values.firstName}
                                                id="firstName"
                                                name="firstName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Владимир"
                                                className={cn('card-input', {
                                                    error:
                                                        touched.firstName &&
                                                        errors.firstName,
                                                })}
                                            />
                                        </div>
                                        {touched.firstName &&
                                        errors.firstName ? (
                                            <div className="card-number-error">
                                                {errors.firstName}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="card-number">
                                        <div className="card-number-label">
                                            Фамилия:
                                        </div>
                                        <div className="card-number-input">
                                            <input
                                                type="text"
                                                value={values.lastName}
                                                id="lastName"
                                                name="lastName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Набоков"
                                                className={cn('card-input', {
                                                    error:
                                                        touched.lastName &&
                                                        errors.lastName,
                                                })}
                                            />
                                        </div>
                                        {touched.lastName && errors.lastName ? (
                                            <div className="card-number-error">
                                                {errors.lastName}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="card-number">
                                        <div className="card-number-label">
                                            Дата рождения:
                                        </div>
                                        <div className="card-number-input">
                                            <input
                                                type="text"
                                                value={values.birthDate}
                                                id="birthDate"
                                                name="birthDate"
                                                onChange={handleChange}
                                                onBlur={(e) => {
                                                    handleBlur(e);
                                                    if (values.birthDate) {
                                                        const clear =
                                                            values.birthDate.replace(
                                                                /[^0-9]/gi,
                                                                ''
                                                            );
                                                        if (
                                                            clear.length !== 8
                                                        ) {
                                                            return setFieldValue(
                                                                'birthDate',
                                                                ''
                                                            );
                                                        }

                                                        const [
                                                            ,
                                                            day,
                                                            month,
                                                            year,
                                                        ] = clear.match(
                                                            /^([0-9]{2})([0-9]{2})([0-9]{4})$/i
                                                        );
                                                        const dayN = parseInt(
                                                            day,
                                                            10
                                                        );
                                                        const monthN = parseInt(
                                                            month,
                                                            10
                                                        );
                                                        const yearN = parseInt(
                                                            year,
                                                            10
                                                        );

                                                        if (
                                                            dayN < 1 ||
                                                            dayN > 31 ||
                                                            monthN < 1 ||
                                                            monthN > 12 ||
                                                            yearN < 1880 ||
                                                            yearN > 2006
                                                        ) {
                                                            return setFieldValue(
                                                                'birthDate',
                                                                ''
                                                            );
                                                        }

                                                        setFieldValue(
                                                            'birthDate',
                                                            `${
                                                                dayN < 10
                                                                    ? '0'
                                                                    : ''
                                                            }${dayN}.${
                                                                monthN < 10
                                                                    ? '0'
                                                                    : ''
                                                            }${monthN}.${yearN}`
                                                        );
                                                    }
                                                }}
                                                placeholder="24.04.1889"
                                                className={cn('card-input', {
                                                    error:
                                                        touched.birthDate &&
                                                        errors.birthDate,
                                                })}
                                            />
                                        </div>
                                        {touched.birthDate &&
                                        errors.birthDate ? (
                                            <div className="card-number-error">
                                                {errors.birthDate}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="card-number">
                                        <div className="card-number-label">
                                            Номер:
                                        </div>
                                        <div className="card-number-input">
                                            <input
                                                type="text"
                                                value={values.number}
                                                id="number"
                                                name="number"
                                                onChange={handleChange}
                                                onBlur={(e) => {
                                                    handleBlur(e);
                                                    if (values.number) {
                                                        const clear =
                                                            values.number.replace(
                                                                /[^0-9]/gi,
                                                                ''
                                                            );
                                                        if (
                                                            clear.length !== 10
                                                        ) {
                                                            return setFieldValue(
                                                                'number',
                                                                ''
                                                            );
                                                        }

                                                        const [
                                                            ,
                                                            day,
                                                            month,
                                                            year,
                                                        ] = clear.match(
                                                            /^([0-9]{2})([0-9]{2})([0-9]{6})$/i
                                                        );
                                                        const dayN = parseInt(
                                                            day,
                                                            10
                                                        );
                                                        const monthN = parseInt(
                                                            month,
                                                            10
                                                        );
                                                        const yearN = parseInt(
                                                            year,
                                                            10
                                                        );

                                                        if (
                                                            dayN < 1 ||
                                                            monthN < 1 ||
                                                            yearN < 1
                                                        ) {
                                                            return setFieldValue(
                                                                'number',
                                                                ''
                                                            );
                                                        }

                                                        setFieldValue(
                                                            'number',
                                                            `${
                                                                dayN < 10
                                                                    ? '0'
                                                                    : ''
                                                            }${dayN} ${
                                                                monthN < 10
                                                                    ? '0'
                                                                    : ''
                                                            }${monthN} ${yearN}`
                                                        );
                                                    }
                                                }}
                                                placeholder="01 01 123456"
                                                className={cn('card-input', {
                                                    error:
                                                        touched.number &&
                                                        errors.number,
                                                })}
                                            />
                                        </div>
                                        {touched.number && errors.number ? (
                                            <div className="card-number-error">
                                                {errors.number}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="rights-buttons">
                                    <Button
                                        type="submit"
                                        isDisabled={
                                            !(
                                                touched.firstName &&
                                                !errors.firstName &&
                                                touched.lastName &&
                                                !errors.lastName &&
                                                touched.birthDate &&
                                                !errors.birthDate &&
                                                touched.number &&
                                                !errors.number &&
                                                values.number &&
                                                values.birthDate &&
                                                values.firstName &&
                                                values.lastName
                                            )
                                        }
                                        isFull
                                    >
                                        Добавить
                                    </Button>
                                    <Button type="submit" isFull>
                                        Отмена
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
                <div
                    className={cn('section', { active: section === 1 })}
                    style={{ gridTemplateColumns: '500px' }}
                >
                    <CloseButton onClick={close} customClass="section-close" />
                    <div className="head" style={{ marginBottom: '30px' }}>
                        Спасибо! Документы отправлены на проверку. Скоро
                        расскажем о результатах
                    </div>
                    <Button type="button" onClick={done} isFull>
                        Принято
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const RightsPickerButton = (props) => {
    const { rights, onClick } = props;

    return (
        <div
            onClick={onClick}
            className={cn('np-button', { filled: !!rights })}
        >
            <div className="np-text">
                {!!rights ? 'Права' : 'Добавить права'}
            </div>
        </div>
    );
};

export default RightsPicker;
