import React from 'react';
import cn from 'classnames';

import './styles.css';
import { types } from '../../constants';
import { getDistances } from '../../utils/directions';

const SmartButton = (props) => {
    const {
        phone,
        type,
        state,
        destinations,
        bakedResults,
        showOrder,
        rights,
        payment,
    } = props;

    let distance = 0;
    let secondary = '';
    let invalid = true;

    if (![types.TAXI, types.DRIVE].includes(type) || state !== 2) {
        return null;
    }

    if (destinations.isValid) {
        distance = getDistances(
            destinations.from,
            destinations.to
        )?.distanceAuto;

        invalid = false;

        if (bakedResults.duration) {
            secondary = `Маршрут составит ${Math.round(
                distance
            )} км. и займёт ${Math.round(bakedResults.duration)} мин.`;
        }
    }

    let main = type === types.TAXI ? 'Заказать' : 'Забронировать';

    if (!phone && type === types.TAXI) {
        main = 'Ввести номер и заказать';
        invalid = true;
    } else {
        invalid = false;
    }

    if ((!payment || payment !== 'card') && type === types.DRIVE) {
        main = 'Добавить оплату и забронировать';
        invalid = true;
    } else {
        invalid = false;
    }

    if (!rights && type === types.DRIVE) {
        main = 'Ввести права и забронировать';
        invalid = true;
    } else {
        invalid = false;
    }

    const show = () => {
        if (!invalid) {
            showOrder();
        }
    };

    return (
        <div className="smart-button-wrapper">
            <button type="button" className="smart-button" onClick={show}>
                <span
                    className={cn('smart-button-main', { single: !secondary })}
                >
                    {main}
                </span>
                {!!secondary && (
                    <span className="smart-button-secondary">{secondary}</span>
                )}
            </button>
        </div>
    );
};

export default SmartButton;
