import React from 'react';
import cn from 'classnames';

import './styles.css';

const CloseButton = (props) => {
    const { onClick, customClass } = props;

    return (
        <button className={cn('close-button', customClass)} onClick={onClick} />
    );
};

export default CloseButton;
