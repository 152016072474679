import React, { useState } from 'react';
import cn from 'classnames';
import CloseButton from '../CloseButton';
import './styles.css';
import cash from '../../assets/cash.svg';
import card from '../../assets/card.svg';
import plusIcon from '../../assets/plus.svg';
import arrow from '../../assets/chewron.svg';
import { Formik } from 'formik';

import { payments } from '../../App';

import Checkbox from '../Checkbox';
import Button from '../Button';
import { API_URL, types } from '../../constants';

const validate = (values) => {
    const errors = {};

    if (!/^[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}$/i.test(values.number)) {
        errors.number = 'Некорректный номер';
    }

    if (!/^[0-9]{2}$/i.test(values.code)) {
        errors.code = 'Неверный код';
    }

    return errors;
};

const PaymentPicker = (props) => {
    const { isOpen = false, payment, changePayment, done, type } = props;
    const [section, setSection] = useState(0);
    const [cards, setCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(0);

    const close = () => {
        done();
        setSection(0);
    };

    const addNewCard = (card, { resetForm }) => {
        const newArray = [...cards, card];
        setCards(newArray);
        setSelectedCard(newArray.indexOf(card));
        changePayment(payments.CARD);
        setSection(0);
        resetForm();
        return fetch(`${API_URL}/api/v1/number/card`, {
            method: 'post',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(card),
        });
    };

    const Row = ({
        title,
        icon,
        id,
        checked,
        onClick,
        plus = false,
        disabled = false,
    }) => {
        return (
            <div className={cn('pp-row', { disabled })} onClick={onClick}>
                <div className="pp-img-container">
                    <img src={icon} alt="Icon" className="pp-img" />
                </div>
                <div className="pp-title">{title}</div>
                <div className="pp-checkbox">
                    {plus ? (
                        <div className="pp-plus-container">
                            <img
                                src={plusIcon}
                                className="pp-plus"
                                alt="plus"
                            />
                        </div>
                    ) : (
                        <Checkbox
                            id={id}
                            name={id}
                            checked={checked}
                            onChange={() => undefined}
                        />
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className={cn('payment-picker', { open: isOpen })}>
            <div className="overlay" />
            <div className={cn('modal', { unusual: section === 1 })}>
                <div className={cn('section', { active: section === 0 })}>
                    <CloseButton onClick={close} customClass="section-close" />
                    <div className="head">Способ оплаты</div>
                    <div className="pp-selector">
                        {type === types.TAXI ? (
                            <Row
                                title="Наличные"
                                icon={cash}
                                id="cash"
                                checked={payment === payments.CASH}
                                onClick={() => changePayment(payments.CASH)}
                            />
                        ) : null}
                        <div className="pp-separator" />
                        {cards.map((cd, key) => (
                            <>
                                <Row
                                    key={key}
                                    icon={card}
                                    id={`card-${key + 1}`}
                                    title={`Карта`}
                                    checked={
                                        payment === payments.CARD &&
                                        selectedCard === key
                                    }
                                    onClick={() => {
                                        changePayment(payments.CARD);
                                        setSelectedCard(key);
                                    }}
                                />
                                <div className="pp-separator" />
                            </>
                        ))}
                        <Row
                            title="Добавить карту"
                            icon={card}
                            id="add-card"
                            disabled
                            plus
                            onClick={() => setSection(1)}
                        />
                    </div>
                </div>
                <div
                    className={cn('section', {
                        active: section === 1,
                        unusual: true,
                    })}
                >
                    <CloseButton onClick={close} customClass="section-close" />
                    <div className="head">Добавление карты</div>
                    <Formik
                        initialValues={{
                            number: undefined,
                            code: undefined,
                        }}
                        onSubmit={(values, options) => {
                            addNewCard(values, options).then((r) =>
                                console.log(r)
                            );
                        }}
                        validate={validate}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="card-wrapper">
                                    <div className="card-number">
                                        <div className="card-number-label">
                                            Номер карты (не нужно свой):
                                        </div>
                                        <div className="card-number-input">
                                            <input
                                                type="text"
                                                value={values.number}
                                                id="number"
                                                name="number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="1234 0000 4321"
                                                className={cn('card-input', {
                                                    error:
                                                        touched.number &&
                                                        errors.number,
                                                })}
                                            />
                                        </div>
                                        {touched.number && errors.number ? (
                                            <div className="card-number-error">
                                                {errors.number}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="card-second-row">
                                        <div
                                            className="plc"
                                        />
                                        <div className="card-code">
                                            <div className="card-code-label">
                                                Код:
                                            </div>
                                            <div className="card-code-input">
                                                <input
                                                    type="text"
                                                    value={values.code}
                                                    id="code"
                                                    name="code"
                                                    placeholder="12"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={cn(
                                                        'card-input',
                                                        {
                                                            error:
                                                                touched.code &&
                                                                errors.code,
                                                        }
                                                    )}
                                                />
                                            </div>
                                            {touched.code && errors.code ? (
                                                <div className="card-code-error">
                                                    {errors.code}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="pp-separator"
                                    style={{ marginTop: '30px' }}
                                />
                                <div className="pp-buttons">
                                    <Button
                                        type="submit"
                                        isDisabled={
                                            !(
                                                touched.number &&
                                                !errors.number &&
                                                touched.code &&
                                                !errors.code &&
                                                values.number &&
                                                values.code
                                            )
                                        }
                                        isFull
                                    >
                                        Привязать
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => setSection(0)}
                                        isFull
                                    >
                                        Отмена
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export const PaymentPickerButton = (props) => {
    const { payment, onClick, type } = props;

    let pText = payment === payments.CASH ? 'Наличные' : 'Карта';
    let pImage = payment === payments.CASH ? cash : card;

    if (type === types.DRIVE) {
        if (payment === payments.CASH) {
            pText = 'Добавить';
            pImage = card;
        }
    }

    return (
        <div
            onClick={onClick}
            className={cn('pp-button', { filled: !!payment })}
        >
            <div className="pp-text">Способ оплаты</div>
            <div className="pp-value">
                <div className="pp-value-text">{pText}</div>
                <div className="pp-value-container">
                    <img src={pImage} alt={payment} />
                </div>
                <div className="pp-value-arrow">
                    <img src={arrow} alt="Arrow right" />
                </div>
            </div>
        </div>
    );
};

export default PaymentPicker;
