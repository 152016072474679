import React from 'react';

import './styles.css';

const Checkbox = (props) => {
    const { checked = false, onChange, label, id, name } = props;

    return (
        <label htmlFor={id} className="checkbox-label">
            {label}
            <input
                onChange={onChange}
                type="checkbox"
                className="checkbox"
                checked={checked}
                id={id}
                name={name}
            />
            <span className="checkmark" />
        </label>
    );
};

export default Checkbox;
