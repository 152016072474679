import React from 'react';
import cn from 'classnames';
import './styles.css';

const Switch = (props) => {
    const { checked, toggle, round } = props;

    return (
        <div className="switch" onClick={() => toggle()}>
            <input type="checkbox" checked={checked} className="switch-input" />
            <span className={cn('slider', { round })} />
        </div>
    );
};

export default Switch;
