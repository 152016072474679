import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {
    types,
    modes,
    modeNames,
    typeIcons,
    modesFlat,
    typesFlat,
    API_URL,
} from '../../constants';
import './styles.css';
import Button from '../Button';
import {states} from '../../App';
import taxiCar from '../../assets/cars/new/taxi.svg';
import carSharing from '../../assets/cars/new/carsharing.svg';
import Cookie from "js-cookie";

const COOKIE_NAME = 'tc-choose-cookie';

const COOKIE_MAP = {
    '1': types.CAR,
    '2': types.WALK,
    '3': types.TAXI,
    '4': types.BIKE,
    '5': types.SCOOTER,
    '6': types.DRIVE,
    '7': 'helicopter',
}

const COOKIE_BROKEN_MAP = {
    [types.CAR]: '2',
    [types.WALK]: '3',
    [types.TAXI]: '5',
    [types.BIKE]: '4',
    [types.SCOOTER]: '7',
    [types.DRIVE]: '1',
    'helicopter': '6',
}

const TypePicker = (props) => {
    const {
        type,
        setType,
        mode,
        setMode,
        state,
        bakedResults,
        next,
        needToShowCustom = false,
    } = props;
    const [bTypes, setBTypes] = useState([]);

    useEffect(() => {
        return fetch(`${API_URL}/api/v1/types`)
            .then((res) => res.json())
            .then((res) => {
                setBTypes(res);
            });
    }, []);

    useEffect(() => {
        if (mode === modes.CUSTOM) {
            const customType = Cookie.get(COOKIE_NAME);

            if (COOKIE_MAP[`${customType}`]) {
                setType(COOKIE_MAP[`${customType}`]);
            }
        }
    }, [mode, setType]);

    useEffect(() => {
        if (Cookie.get(COOKIE_NAME)) {
            Cookie.set(COOKIE_NAME, COOKIE_BROKEN_MAP[type] ?? '0')
        }

    }, [type])

    const bTypesFlat = bTypes
        .map(({id}) => id)
        .filter((id) => !typesFlat.includes(id));
    const bTypesMap = bTypes.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
    }, {});

    const isPickerShown = state === states.TYPE_PICK;

    const renderResults = () => {
        if (!bakedResults) {
            return;
        }

        const {priceText, durationText} = bakedResults;

        return (
            <div className="results-container">
                <div className="results-text">
                    <div className="text">{priceText}</div>
                    <div className="duration">{durationText}</div>
                    {type === types.TAXI ? (
                        <Button onClick={next} isRound>
                            Вызвать такси
                        </Button>
                    ) : null}
                    {type === types.DRIVE ? (
                        <Button onClick={next} isRound>
                            Забронировать
                        </Button>
                    ) : null}
                </div>
                <div
                    className={cn('results-extra', {
                        hidden: type !== types.TAXI && type !== types.DRIVE,
                    })}
                >
                    <img
                        className="results-image"
                        style={{
                            maxWidth: type === types.TAXI ? 'unset' : '175px',
                        }}
                        src={type === types.TAXI ? taxiCar : carSharing}
                        alt="Taxi"
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={cn('type-picker', {shown: isPickerShown})}>
            <div className="modes-container">
                {modesFlat
                    .filter((v) => !(v === modes.CUSTOM && !needToShowCustom))
                    .map((md) => {
                        return (
                            <div
                                key={md}
                                onClick={() => setMode(md)}
                                className={cn('mode', {active: md === mode})}
                            >
                                {modeNames[md]}
                            </div>
                        );
                    })}
            </div>
            <div className="types-container">
                {[...typesFlat, ...bTypesFlat].map((tp) => {
                    let icon;
                    let isActive = tp === type;

                    const bType = bTypesMap[tp];
                    if (bType) {
                        const bInactive = bType.icons.inactive;
                        const bActive = bType.icons.active;

                        const patient = isActive ? bActive : bInactive;

                        const subActive = patient.includes('active');
                        const subType = patient.replace(/[.-].*?$/, '');
                        icon = (typeIcons[subType] || typeIcons[tp])[
                            subActive ? 'active' : 'default'
                            ];
                    } else {
                        icon = typeIcons[tp][isActive ? 'active' : 'default'];
                    }

                    return (
                        <div
                            key={tp}
                            onClick={() => {
                                if (mode !== modes.CUSTOM) {
                                    return;
                                }

                                setType(tp);
                            }}
                            className={cn('type', {
                                active: isActive,
                                disabled: mode !== modes.CUSTOM,
                                drive: tp === types.DRIVE,
                            })}
                        >
                            <img src={icon} className="type-icon"/>
                        </div>
                    );
                })}
            </div>
            {renderResults()}
        </div>
    );
};

export default TypePicker;
