import React from 'react';
import cn from 'classnames';

import './styles.css';

const IButton = (props) => {
    const { onClick, customClass, active } = props;

    return (
        <button
            {...props}
            className={cn('i-button', customClass, { active })}
            onClick={onClick}
        ></button>
    );
};

export default IButton;
