import React, { Fragment } from 'react';
import logoImage from '../../assets/logo2.svg';

import './styles.css';

const Logo = () => {
    const logoComponent = () => (
        <Fragment>
            <img
                src={logoImage}
                alt="Яндекс"
                className="logo-image"
                style={{ width: '180px' }}
            />
            {/*<span className="logo-fancy">Маршруты</span>*/}
            <span className="logo-disclaimer">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Учебный тренажёр</span>
        </Fragment>
    );

    return (
        <Fragment>
            <div className="logo">{logoComponent()}</div>
        </Fragment>
    );
};

export default Logo;
