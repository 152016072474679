import React, { useEffect, useState } from 'react';

import cn from 'classnames';

import plus from '../../assets/plus.svg';
import burger from '../../assets/burger.svg';
import cash from '../../assets/cash.svg';
import card from '../../assets/card.svg';
import i from '../../assets/i.svg';
import economy from '../../assets/cars/new/economy.svg';
import comfort from '../../assets/cars/new/comfort.svg';
import comfort_plus from '../../assets/cars/new/comfort_plus.svg';
import van from '../../assets/cars/new/van.svg';
import business from '../../assets/cars/new/business.svg';
import kids from '../../assets/cars/new/kids.svg';
import chewron from '../../assets/chewron.svg';
import bender from '../../assets/bender.svg';
import a from '../../assets/sharing/everyday.svg';
import b from '../../assets/sharing/camping.svg';
import c from '../../assets/sharing/luxury.svg';
import './styles.css';
import { API_URL, RequirementsList, TariffIds, Tariffs } from '../../constants';

const carIcons = {
    [Tariffs.ECONOMY]: economy,
    [Tariffs.COMFORT]: comfort,
    [Tariffs.COMFORT_PLUS]: comfort_plus,
    [Tariffs.VAN]: van,
    [Tariffs.BUSINESS]: business,
    [Tariffs.KIDS]: kids,
    [Tariffs.DRIVE_1]: a,
    [Tariffs.DRIVE_2]: b,
    [Tariffs.DRIVE_3]: c,
};

const globalKeys = Object.keys(RequirementsList).map((key) => ({
    key,
    value: RequirementsList[key],
}));

function requirementsProcessing(reqs) {
    if (reqs === null || !reqs) {
        return null;
    }

    if (['string', 'number', 'boolean'].includes(typeof reqs)) {
        return reqs;
    }

    if (Array.isArray(reqs)) {
        return reqs.map(requirementsProcessing);
    }

    return Object.keys(reqs)
        .map((key) => ({ key, value: reqs[key] }))
        .map(({ key, value }) => ({
            value: requirementsProcessing(value),
            key: (
                globalKeys.find((gK) => `${gK.value}` === `${key}`) || { key }
            ).key,
        }))
        .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {});
}

const Order = (props) => {
    const {
        shown,
        destinations,
        payment,
        activeTariff,
        tariffPrices,
        comment,
        features,
        duration,
        phone,
        reset,
    } = props;
    const [time, setTime] = useState(0);
    const [maxTime, setMaxTime] = useState(0);
    const [stage, setStage] = useState(0);
    const [showDetails, setShowDetails] = useState(false);
    const [randomTime, setRandomTime] = useState(0);
    const [randomNumber, setRandomNumber] = useState('А 270 АВ');
    const [randomName, setRandomName] = useState('Аркадий');
    const [need2FetchName, fetchName] = useState(false);
    const [orderId, setOrderId] = useState(0);
    const actualTariffObj = (tariffPrices || []).find(
        (t) => t.tariff === activeTariff
    );

    const isDrive = [
        Tariffs.DRIVE_1,
        Tariffs.DRIVE_2,
        Tariffs.DRIVE_3,
    ].includes(activeTariff);

    let actualTariffPrice = 150;

    if (actualTariffObj) {
        actualTariffPrice = actualTariffObj.price;
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (time - 1 <= 0) {
                clearTimeout(timeout);
                setStage(1);
            } else {
                setTime(time - 1);
                if (time - 1 === maxTime - 3) {
                    fetchName(true);
                }
            }
        }, 1000);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [maxTime, time]);

    useEffect(() => {
        if (!need2FetchName || !orderId || isDrive) {
            return;
        }

        return fetch(`${API_URL}/api/v1/order/${orderId}`)
            .then((r) => r.json())
            .then((r) => {
                setRandomTime(r.eta);
                setRandomName(r.name);
                setRandomNumber(r.number);
            });
    }, [need2FetchName, orderId, isDrive]);

    useEffect(() => {
        if (shown) {
            setStage(0);
            return fetch(`${API_URL}/api/v1/order`, {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    tariff: TariffIds[activeTariff],
                    payment,
                    comment,
                    features: requirementsProcessing(features),
                    duration,
                    phone: isDrive ? undefined : phone,
                }),
            })
                .then((r) => r.json())
                .then((r) => {
                    if (r.status === 500) {
                        return reset();
                    }

                    setTime(isDrive ? 10 * 60 : r.eta);
                    setMaxTime(isDrive ? 10 * 60 : r.eta);
                    setOrderId(r.id);
                })
                .catch(() => {
                    reset();
                });
        }
    }, [
        activeTariff,
        comment,
        duration,
        features,
        payment,
        phone,
        reset,
        shown,
        isDrive,
    ]);

    return (
        <div className={cn('order', { shown })}>
            <div className={'overlay'} />
            {isDrive ? (
                <div className={cn('order-body')}>
                    <div className="order-header" style={{ height: '83px' }}>
                        <div
                            style={{
                                fontFamily: 'YS text',
                                fontStyle: 'normal',
                                fontSize: '28px',
                                lineHeight: '34px',
                                color: '#21201F',
                                display: 'grid',
                                alignItems: 'center',
                                padding: '14px 16px 0',
                            }}
                        >
                            Машина забронирована
                        </div>
                        <div
                            className="order-header-content"
                            style={{ paddingTop: 0 }}
                        >
                            <div className="order-header-title">
                                Бесплатное ожидание
                            </div>
                            <div className="order-header-time">{`${Math.floor(
                                time / 60
                            )}:${time % 60 < 10 ? `0` : ''}${Math.round(
                                time % 60
                            )}`}</div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            alignItems: 'center',
                            borderBottom: '1px solid #D2D0CC',
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'YS text',
                                fontStyle: 'normal',
                                fontSize: '28px',
                                lineHeight: '34px',
                                color: '#21201F',
                                display: 'grid',
                                alignItems: 'center',
                                padding: '14px 16px 0',
                                textAlign: 'center',
                            }}
                        >
                            {activeTariff}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <img
                                style={{
                                    width: '250px',
                                }}
                                src={carIcons[activeTariff]}
                                alt="Car"
                            />
                        </div>
                    </div>
                    <div className="order-subbody">
                        <div className="order-btn-group">
                            <button
                                onClick={() => {
                                    throw new Error('Unimplemented method');
                                }}
                                type="button"
                                className="order-button"
                            >
                                <img
                                    src={plus}
                                    style={{ transform: 'rotate(45deg)' }}
                                    alt="close"
                                />
                            </button>
                            <div>Отменить</div>
                        </div>
                        <div
                            className={cn('order-details', {
                                shown: true,
                            })}
                        >
                            <div className="order-details-row">
                                <div className="order-details-icon">
                                    <div
                                        className="o-circle"
                                        style={{ background: '#2B9FB1' }}
                                    />
                                </div>
                                <div className="order-details-content">
                                    <div className="o-d-h">
                                        {destinations?.from}
                                    </div>
                                    <div className="o-d-sh">Адрес машины</div>
                                </div>
                            </div>
                            <div className="order-details-row">
                                <div className="order-details-icon">
                                    <img src={i} alt="Info" />
                                </div>
                                <div className="order-details-content">
                                    <div className="o-d-h">Еще про поездку</div>
                                    <div className="o-d-sh">
                                        Стоимость - {actualTariffPrice}₽
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={cn('order-body')}>
                    <div className="order-header">
                        {stage === 0 ? (
                            <div className="order-header-content">
                                <div className="order-header-title">
                                    Поиск машины
                                </div>
                                <div className="order-header-time">{`00:${
                                    time < 10 ? `0` : ''
                                }${time}`}</div>
                                <div
                                    className={cn('order-progress', {
                                        visible: time > 0,
                                    })}
                                    style={{
                                        width: `${
                                            100 - (time / maxTime) * 100
                                        }%`,
                                    }}
                                />
                            </div>
                        ) : (
                            <div className="order-header-content">
                                <div className="order-header-title">
                                    {randomTime} мин. и приедет{' '}
                                    <img src={chewron} alt="" />
                                </div>
                                <div className="order-number">
                                    <div className="number">{randomNumber}</div>
                                    <img
                                        style={{
                                            position: 'absolute',
                                            right: -12,
                                            bottom: -16,
                                            width: '52px',
                                        }}
                                        src={carIcons[activeTariff]}
                                        alt="Car"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="order-subbody">
                        <div className="order-buttons">
                            {stage === 1 ? (
                                <div className="order-btn-group">
                                    <div
                                        style={{ cursor: 'default' }}
                                        className="order-button"
                                    >
                                        <div className="order-btn-rating">
                                            4,9
                                        </div>
                                        <img
                                            src={bender}
                                            style={{}}
                                            alt="close"
                                        />
                                    </div>
                                    <div>{randomName}</div>
                                </div>
                            ) : null}
                            <div className="order-btn-group">
                                <button
                                    onClick={() => {
                                        throw new Error('Unimplemented method');
                                    }}
                                    type="button"
                                    className="order-button"
                                >
                                    <img
                                        src={plus}
                                        style={{ transform: 'rotate(45deg)' }}
                                        alt="close"
                                    />
                                </button>
                                <div>Отменить</div>
                            </div>
                            <div className="order-btn-group">
                                <button
                                    type="button"
                                    onClick={() => setShowDetails(!showDetails)}
                                    className="order-button"
                                >
                                    <img src={burger} alt="burger" />
                                </button>
                                <div>Детали</div>
                            </div>
                        </div>
                        <div
                            className={cn('order-details', {
                                shown: showDetails,
                            })}
                        >
                            <div className="order-details-row">
                                <div className="order-details-icon">
                                    <div
                                        className="o-circle"
                                        style={{ background: '#FC5230' }}
                                    />
                                </div>
                                <div className="order-details-content">
                                    <div className="o-d-h">
                                        {destinations?.from}
                                    </div>
                                    <div className="o-d-sh">Адрес подачи</div>
                                </div>
                            </div>
                            <div className="order-details-row">
                                <div className="order-details-icon">
                                    <div
                                        className="o-circle"
                                        style={{ background: '#007EFF' }}
                                    />
                                </div>
                                <div className="order-details-content">
                                    <div className="o-d-h">
                                        {destinations?.to}
                                    </div>
                                    <div className="o-d-sh">
                                        Адрес назначения
                                    </div>
                                </div>
                            </div>
                            <div className="order-details-row">
                                <div className="order-details-icon">
                                    <img
                                        src={payment === 'cash' ? cash : card}
                                        alt={payment}
                                        style={{ height: '24px' }}
                                    />
                                </div>
                                <div className="order-details-content">
                                    <div className="o-d-h">
                                        {payment === 'cash'
                                            ? 'Наличные'
                                            : 'Карта'}
                                    </div>
                                    <div className="o-d-sh">Способ оплаты</div>
                                </div>
                            </div>
                            <div className="order-details-row">
                                <div className="order-details-icon">
                                    <img src={i} alt="Info" />
                                </div>
                                <div className="order-details-content">
                                    <div className="o-d-h">Еще про поездку</div>
                                    <div className="o-d-sh">
                                        Стоимость - {actualTariffPrice}₽
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Order;
