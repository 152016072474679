import React from 'react';
import cn from 'classnames';

import './styles.css';
import { states } from '../../App';
import ArrowButton from '../ArrowButton';

import economy from '../../assets/cars/new/economy.svg';
import comfort from '../../assets/cars/new/comfort.svg';
import comfortp from '../../assets/cars/new/comfort_plus.svg';
import van from '../../assets/cars/new/van.svg';
import business from '../../assets/cars/new/business.svg';
import kids from '../../assets/cars/new/kids.svg';

import drive_1 from '../../assets/sharing/everyday.svg';
import drive_2 from '../../assets/sharing/camping.svg';
import drive_3 from '../../assets/sharing/luxury.svg';

import TariffCard from '../TariffCard';
import { NumberPickerButton } from '../NumberPicker';
import { PaymentPickerButton } from '../PaymentPicker';
import Input from '../Input';
import Requirements from '../Requirements';
import { Tariffs, types } from '../../constants';
import { RightsPickerButton } from '../RightsPicker';

export const tarfiffsDrive = [
    {
        price: 150,
        title: Tariffs.DRIVE_1,
        icon: drive_1,
        car: 'BMW 750',
        prefix: 'Просто по делам, ничего лишнего',
        postfix: <>видеорегистратор &#183; зарядка для телефона</>,
        mins: 15,
    },
    {
        price: 250,
        title: Tariffs.DRIVE_2,
        icon: drive_2,
        car: 'Kia Rio',
        prefix: 'Для путешествий',
        postfix: <>Bluetooth-двери &#183; походное снаряжение</>,
        mins: 12,
    },
    {
        price: 300,
        title: Tariffs.DRIVE_3,
        icon: drive_3,
        car: 'Porsche 911',
        prefix: 'Блеск, мощь, глянец',
        postfix: <>светомузыка &#183; напитки для гостей</>,
        mins: 10,
    },
];

export const tariffs = [
    {
        price: 150,
        title: Tariffs.ECONOMY,
        icon: economy,
        prefix: 'Для деловых особ, которых отвлекают',
        postfix: (
            <>
                — В салоне нет доступа к асоциальным сетям <br />— Выдвижной
                столик для ноутбука
            </>
        ),
    },
    {
        price: 250,
        title: Tariffs.COMFORT,
        icon: comfort,
        prefix: 'Если мысли не выходят из головы',
        postfix: (
            <>
                — Вас сопровождает спутник с тремя высшими образованиями
                <br />— Можете обсудить любую тему — от трендов в Тик-токе до
                квантовой механики
            </>
        ),
    },
    {
        price: 300,
        title: Tariffs.COMFORT_PLUS,
        icon: comfortp,
        prefix: 'Если пришла пора отдохнуть',
        postfix: (
            <>
                — В салоне массажное кресло <br />— В бардачке — расслабляющие
                маски для лица и патчи.
            </>
        ),
    },
    {
        price: 480,
        title: Tariffs.VAN,
        icon: van,
        prefix: 'Для тех, кто не выспался',
        postfix: (
            <>
                — В салоне кресло-кровать <br />
                — На сиденье мыгкая игрушка — по желанию <br />— Водитель ведёт
                плавно, чтобы вы дремали сладко
            </>
        ),
    },
    {
        price: 350,
        title: Tariffs.KIDS,
        icon: kids,
        prefix: 'Если хочется свернуться калачиком',
        postfix: (
            <>
                — В салоне мягкий плед и носовые платки <br />
                — Водитель за звуконепроницаемой шторкой <br />— Персональное
                ведёрко с мороженым
            </>
        ),
    },
    {
        price: 150,
        title: Tariffs.BUSINESS,
        icon: business,
        prefix: 'Если нужно блистать',
        postfix: (
            <>
                — В салоне неоновая подсветка <br />
                — С потолка автомобиля непрерывно летят блёстки <br />— Салон
                прошёл верификацию на инстаграммность у лучших экспертов
            </>
        ),
    },
];

const TariffPicker = (props) => {
    const { state, prev, setActiveTariff, activeTariff, type, tariffPrices } =
        props;

    const isShown = state === states.TARIFF_PICK;

    const trfs = type === types.TAXI ? tariffs : tarfiffsDrive;
    const selected = trfs.find(({ title }) => title === activeTariff);

    return (
        <div className={cn('tariff-picker', { shown: isShown })}>
            <ArrowButton onClick={prev} customClass="tariffs-arrow-button" />
            <div className="tariff-cards">
                {trfs.map((tariff, key) => {
                    const found = (tariffPrices || []).find(
                        (t) => t.tariff === tariff.title
                    );
                    let tariffPrice = tariff.price;
                    if (found) {
                        tariffPrice = Math.round(found.price);
                    }

                    return (
                        <TariffCard
                            id={key}
                            key={tariff.title}
                            title={tariff.title}
                            icon={tariff.icon}
                            price={tariffPrice}
                            active={activeTariff === tariff.title}
                            onClick={() => setActiveTariff(tariff.title)}
                            prefix={tariff.prefix}
                            postfix={tariff.postfix}
                            drive={type === types.DRIVE}
                        />
                    );
                })}
            </div>

            {type === types.DRIVE ? (
                <div className="drive-preview">
                    <div className="drive-preview-title">{selected?.car}</div>
                    <div className="drive-preview-prefix">
                        {selected?.prefix}
                    </div>
                    <div className="drive-preview-stats">
                        4 мин. &#183; {selected?.mins} мин. бесплатного ожидания
                    </div>
                    <div className="drive-preview-img">
                        <img src={selected?.icon} alt={selected?.car} />
                    </div>
                    <div className="drive-preview-postfix">
                        {selected?.postfix}
                    </div>
                </div>
            ) : null}

            <div className="form">
                {type === types.TAXI ? (
                    <NumberPickerButton
                        phone={props.phone}
                        onClick={props.onPhoneClick}
                    />
                ) : (
                    <RightsPickerButton
                        rights={props.rights}
                        onClick={props.onRightsClick}
                    />
                )}

                <PaymentPickerButton
                    payment={props.payment}
                    type={type}
                    onClick={props.onPaymentClick}
                />
                {type === types.TAXI ? (
                    <div style={{ marginTop: '12px' }}>
                        <Input
                            value={props.comment}
                            onChange={props.setComment}
                            label="Комментарий водителю..."
                            withoutButton
                            id="comment"
                            name="comment"
                            placeholder="Захватите виски"
                        />
                    </div>
                ) : null}
                <Requirements
                    {...props}
                    tariffs={[...tariffs, ...tarfiffsDrive]}
                />
            </div>
        </div>
    );
};
export default TariffPicker;
