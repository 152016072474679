import React, { useEffect, useState } from 'react';
import { isMobileYabro } from '@detect-yabro/common';
import cn from 'classnames';
import arrow from '../../assets/chewron.svg';
import './styles.css';
import Switch from '../Switch';
import { RequirementsList, Tariffs } from '../../constants';
import Checkbox from '../Checkbox';
import Counter from '../Counter';

function isYaBro() {
    const ua = navigator.userAgent;
    return isMobileYabro(ua);
}

const rTypes = {
    CHECKBOX: 'switch',
    REDIRECT: 'link',
    COUNTER: 'counter',
    RADIO: 'radio',
    GROUP: 'group',
};

const RequirementsTitles = {
    [RequirementsList.NOTEBOOK_DESC]: 'Столик для ноутбука',
    [RequirementsList.NEON]: 'Неоновая подсветка',
    [RequirementsList.MASSAGE_CHAIR]: 'Массажное кресло',
    [RequirementsList.MASK]: 'Маска',
    [RequirementsList.PATCHES]: 'Патчи',
    [RequirementsList.PLUSHIE]: 'Мягкая игрушка',
    [RequirementsList.BED_DUBAI]: 'Кресло-кровать Дубаи',
    [RequirementsList.BED_YAKUT]: 'Кресло-кровать Якутия',
    [RequirementsList.PILLOW]: 'Подушка',
    [RequirementsList.SOFT_DRIVE]: 'Вести плавно',
    [RequirementsList.PLAID]: 'Плед и носовые платки',
    [RequirementsList.SOUND_PROOF]: 'Звуконепроницаемая шторка',
    [RequirementsList.ICECREAM_PL]: 'Пломбир',
    [RequirementsList.ICECREAM_CH]: 'Шоколадное',
    [RequirementsList.ICECREAM_BR]: 'Клубничное',
    [RequirementsList.SPARKLES]: 'Летят блёстки в машине',
    [RequirementsList.ICECREAM]: 'Ведёрко с мороженым',
    [RequirementsList.BED]: 'Кресло кровать',
    [RequirementsList.FOR_YOURSELF]: 'Для себя',
    [RequirementsList.PHONE_CHARGER]: 'Зарядка для телефона',
    [RequirementsList.COLOR_MUSIC]: 'Светомузыка',
    [RequirementsList.MOSCITOS_SPRAY]: 'Спрей от комаров',
    [RequirementsList.SLEEPBAG]: 'Спальник',
    [RequirementsList.SHAMPAIN]: 'Шампанское для пассажиров',
    [RequirementsList.VINE]: 'Вино для пассажиров',
    [RequirementsList.GLASSES]: 'Очки для крутоты',
    [RequirementsList.FUN]: 'Развлечься',
};

const Requirement = (props) => {
    const { req, type, tariff, value, change, sub, items, max } = props;

    const group = () => {
        const v = { ...(value || {}) };

        return (
            <div className="r-group">
                <div className="r-group-title">{RequirementsTitles[req]}</div>
                <div className="r-group-items">
                    {(items || []).map((item) => (
                        <Requirement
                            sub
                            req={item.req}
                            tariff={item.tariff}
                            type={item.type}
                            items={item.items}
                            max={item.max}
                            value={v[item.req] || item.default}
                            change={(newV) =>
                                change({
                                    ...(v || {}),
                                    [item.req]: newV || item.default,
                                })
                            }
                        />
                    ))}
                </div>
            </div>
        );
    };

    const radio = () => {
        return (
            <div className="r-radios">
                {items.map((item) => {
                    return (
                        <div className="r-radio r">
                            <div className="r-radio-label">
                                {RequirementsTitles[item]}
                            </div>
                            <div className="r-radio-button">
                                <Checkbox
                                    checked={value === item}
                                    onChange={() => change(item)}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const counter = () => {
        return (
            <div
                className={cn('r-counter-container', {
                    'r-counter-container-mobile': isYaBro(),
                })}
            >
                <div className="r-counter-label">{RequirementsTitles[req]}</div>
                <div className="r-counter">
                    <Counter value={value} onChange={change} max={max} />
                </div>
            </div>
        );
    };

    const sw = () => {
        return (
            <div className="r-sw-container">
                <div className="r-sw-label">{RequirementsTitles[req]}</div>
                <div className="r-sw">
                    <Switch
                        checked={!!value}
                        toggle={() => change(!value)}
                        round
                    />
                </div>
            </div>
        );
    };

    const link = () => {
        console.log({ tariff });
        return (
            <div className="r-link" onClick={change}>
                <div className="r-link-label">
                    <div>{RequirementsTitles[req]}</div>
                    <div className="r-link-sublabel">
                        В тариф «{tariff.title}»
                    </div>
                </div>
                <div className="r-right">
                    <div className="r-right-img">
                        <img src={tariff.icon} alt={tariff.title} />
                        <img src={arrow} alt="Arrow right" />
                    </div>
                </div>
            </div>
        );
    };

    const built = () => {
        if (type === rTypes.CHECKBOX) {
            return sw();
        }

        if (type === rTypes.REDIRECT) {
            return link();
        }

        if (type === rTypes.GROUP) {
            return group();
        }

        if (type === rTypes.COUNTER) {
            return counter();
        }

        if (type === rTypes.RADIO) {
            return radio();
        }

        return null;
    };

    return (
        <div className={cn('r', { sub, [`r-type-${type}`]: true })}>
            {built()}
        </div>
    );
};

const tariffDescriptions = {
    [Tariffs.DRIVE_1]: [
        {
            type: rTypes.CHECKBOX,
            req: RequirementsList.PHONE_CHARGER,
        },
        {
            type: rTypes.REDIRECT,
            req: RequirementsList.COLOR_MUSIC,
            tariff: Tariffs.DRIVE_3,
        },
    ],
    [Tariffs.DRIVE_2]: [
        {
            type: rTypes.COUNTER,
            max: 2,
            req: RequirementsList.MOSCITOS_SPRAY,
        },
        {
            type: rTypes.COUNTER,
            max: 5,
            req: RequirementsList.SLEEPBAG,
        },
        {
            type: rTypes.REDIRECT,
            req: RequirementsList.COLOR_MUSIC,
            tariff: Tariffs.DRIVE_3,
        },
    ],
    [Tariffs.DRIVE_3]: [
        {
            type: rTypes.CHECKBOX,
            req: RequirementsList.COLOR_MUSIC,
        },
        {
            type: rTypes.GROUP,
            req: RequirementsList.FUN,
            items: [
                // {
                //     type: rTypes.RADIO,
                //     req: RequirementsList.FUN,
                //     default: RequirementsList.SHAMPAIN,
                //     items: [RequirementsList.SHAMPAIN, RequirementsList.VINE],
                // },
                {
                    type: rTypes.CHECKBOX,
                    req: RequirementsList.SHAMPAIN,
                },
                {
                    type: rTypes.CHECKBOX,
                    req: RequirementsList.VINE,
                },
            ],
        },
        {
            type: rTypes.COUNTER,
            max: 3,
            req: RequirementsList.GLASSES,
        },
    ],

    [Tariffs.ECONOMY]: [
        {
            type: rTypes.CHECKBOX,
            req: RequirementsList.NOTEBOOK_DESC,
        },
        {
            type: rTypes.REDIRECT,
            req: RequirementsList.ICECREAM,
            tariff: Tariffs.KIDS,
        },
        {
            type: rTypes.REDIRECT,
            req: RequirementsList.MASSAGE_CHAIR,
            tariff: Tariffs.COMFORT_PLUS,
        },
    ],
    [Tariffs.VAN]: [
        {
            type: rTypes.CHECKBOX,
            req: RequirementsList.NOTEBOOK_DESC,
        },
        {
            type: rTypes.REDIRECT,
            req: RequirementsList.ICECREAM,
            tariff: Tariffs.KIDS,
        },
        {
            type: rTypes.REDIRECT,
            req: RequirementsList.MASSAGE_CHAIR,
            tariff: Tariffs.COMFORT_PLUS,
        },
    ],
    [Tariffs.BUSINESS]: [
        {
            type: rTypes.CHECKBOX,
            req: RequirementsList.NEON,
        },
        {
            type: rTypes.CHECKBOX,
            req: RequirementsList.SPARKLES,
        },
    ],
    [Tariffs.COMFORT]: [
        {
            type: rTypes.CHECKBOX,
            req: RequirementsList.PLUSHIE,
        },
        {
            type: rTypes.GROUP,
            req: RequirementsList.BED,
            items: [
                {
                    type: rTypes.RADIO,
                    req: RequirementsList.BED,
                    default: RequirementsList.BED_DUBAI,
                    items: [
                        RequirementsList.BED_DUBAI,
                        RequirementsList.BED_YAKUT,
                    ],
                },
                {
                    type: rTypes.COUNTER,
                    max: 3,
                    req: RequirementsList.PILLOW,
                },
            ],
        },
    ],
    [Tariffs.COMFORT_PLUS]: [
        {
            type: rTypes.GROUP,
            req: RequirementsList.FOR_YOURSELF,
            items: [
                {
                    type: rTypes.COUNTER,
                    req: RequirementsList.MASSAGE_CHAIR,
                    // max: 2, 🐞 БАГ
                },
                {
                    type: rTypes.COUNTER,
                    req: RequirementsList.MASK,
                    max: 4,
                },
                {
                    type: rTypes.COUNTER,
                    req: RequirementsList.PATCHES,
                    max: 4,
                },
            ],
        },
        {
            type: rTypes.REDIRECT,
            req: RequirementsList.ICECREAM,
            tariff: Tariffs.KIDS,
        },
    ],
    [Tariffs.KIDS]: [
        {
            type: rTypes.CHECKBOX,
            req: RequirementsList.PLAID,
        },
        {
            type: rTypes.CHECKBOX,
            req: RequirementsList.SOUND_PROOF,
        },
        {
            type: rTypes.GROUP,
            req: RequirementsList.ICECREAM,
            items: [
                {
                    type: rTypes.COUNTER,
                    req: RequirementsList.ICECREAM_PL,
                    max: 2,
                },
                {
                    type: rTypes.COUNTER,
                    req: RequirementsList.ICECREAM_CH,
                    max: 2,
                },
                {
                    type: rTypes.COUNTER,
                    req: RequirementsList.ICECREAM_BR,
                    max: 2,
                },
            ],
        },
    ],
};

const Requirements = (props) => {
    const {
        setActiveTariff,
        activeTariff,
        requirements,
        setRequirements,
        tariffs,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [tariffDescription, selectTariffDescription] = useState([]);
    const updateReq = (path) => (value) => {
        setRequirements({ ...requirements, [path]: value });
    };

    const getReq = (path) => {
        return requirements[path];
    };

    useEffect(() => {
        setIsOpen(activeTariff !== Tariffs.ECONOMY);

        selectTariffDescription(tariffDescriptions[activeTariff] || []);
    }, [activeTariff]);

    const fixedTariffDescription = tariffDescription.map((d) => {
        d.value = getReq(d.req);

        if (d.type === rTypes.REDIRECT) {
            d.change = () => setActiveTariff(d.tariff);
        } else {
            d.change = updateReq(d.req);
        }

        return d;
    });

    return (
        <div className={cn('reqs', { open: isOpen })}>
            <div className="reqs-header" onClick={() => setIsOpen(!isOpen)}>
                <div className="reqs-head">Требования к заказу</div>
                <div className={cn('reqs-arrow', { open: isOpen })}>
                    <img src={arrow} alt="Arrow" />
                </div>
            </div>
            <div className="reqs-body">
                {fixedTariffDescription.map((req) => (
                    <Requirement
                        {...req}
                        tariff={tariffs.find((t) => t.title === req.tariff)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Requirements;
