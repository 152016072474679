import React from 'react';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';

import './styles.css';
import IButton from '../IButton';

const TariffCard = (props) => {
    const { active, title, price, icon, onClick, id, prefix, postfix, drive } =
        props;
    const key = `tariff-card-${id}`;

    const getFloating = () => {
        return (
            <div className="i-floating">
                <div className="i-title">{title}</div>
                <div className="i-dPrefix">{prefix}</div>
                <div className="i-icon">
                    <img src={icon} alt={title} />
                </div>
                <div className="i-dPostfix">{postfix}</div>
            </div>
        );
    };

    return (
        <div className={cn('tcard', { active })} onClick={onClick}>
            {!drive ? (
                <IButton
                    customClass="tcard-i"
                    active
                    data-for={key}
                    data-tip={true}
                />
            ) : null}
            <div className="tcard-icon">
                <img src={icon} alt={title} />
            </div>
            <div className="tcard-title">{title}</div>
            <div className="tcard-price">{price} ₽</div>
            <ReactTooltip
                id={key}
                getContent={() => getFloating()}
                border="none"
                className="i-floating-tooltip"
                backgroundColor="none"
                effect="solid"
            />
        </div>
    );
};

export default TariffCard;
